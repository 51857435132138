import React, { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  StyleRules,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { navigate } from 'gatsby';

import { AppState } from '../../../reducers';
import ConsultantProjectView from './ConsultantProjectView';
import ConsultantViewHeader from './ConsultantViewHeader';

const styles = (): StyleRules =>
  createStyles({
    box: {
      padding: '2rem',
      margin: '1rem auto',
    },
    drawerContainer: {
      width: 500,
      display: 'flex',
      alignItems: 'center',
    },
  });

const ConsultantHomeView = ({ classes }: Props): ReactElement => {
  const consultant = useSelector((state: AppState) => state.consultant);
  const [checked, setChecked] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    if (checked) {
      localStorage.setItem('isConsultantInfo', 'true');
    }
    setOpen(false);
  };

  const handleEnter = () => {
    navigate('/consultant/verification');
  };

  useEffect(() => {
    if (consultant.id && consultant.verification && consultant.verification.basicInfo?.firstName) {
      if (
        !consultant.verification.basicInfo?.passportId &&
        localStorage.getItem('isConsultantInfo') !== 'true'
      ) {
        setOpen(true);
      }
    }
  }, [consultant]);

  return (
    <>
      <Grid xs={12} className={classes.box} item container>
        <Helmet title="傅通自由职业"></Helmet>
        <ConsultantViewHeader />
        <ConsultantProjectView />
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">提示</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            你的个人信息不完整是否需要完善？
            <div>
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
                color="primary"
              />
              不再提醒我
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEnter} color="primary">
            确认
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(ConsultantHomeView);
